<template>
  <div class="software">
    <div class="software-list">
      <div class="left-option">
        <div class="museum">
          <span>智慧博物馆</span>
          <em class="el-icon-arrow-right"></em>
        </div>
        <div class="museum-list">
          <ul>
            <li v-for="item of virtualDataMuseum" :key="item.id" @click="getContent(item.id)" :class="{ textColor: idlogo === item.id }">
              <span>{{ item.title }}</span>
            </li>
          </ul>
        </div>
        <div class="museum">
          <span>智慧城市</span>
          <em class="el-icon-arrow-right"></em>
        </div>
        <div class="museum-list">
          <ul>
            <li v-for="item of virtualDataCity" :key="item.id" @click="getContent(item.id)" :class="{ textColor: idlogo === item.id }">
              <span>{{ item.title }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="right-list-box">
        <div class="right-list" v-if="contentData.id">
          <div class="imgs">
            <!-- <img :src="baseIP + imageShowAPI + contentData.imageUri" alt="" /> -->
            <!-- <img :src="contentData && contentData.contentImageId && baseIP + imageShowAPI + contentData.contentImage.waterMarkUri" alt="" /> -->
            <img v-if="contentData.contentImageId &&contentData.contentImage" :src="contentData.contentImageId && baseIP + imageShowAPI + contentData.contentImage.waterMarkUri" alt="" />
            <img v-else :src="defaultImage" alt="" />
          </div>
          <div class="text">
            <span>{{ contentData.title }}</span>
            <p v-html="contentData.columnsContent"></p>
          </div>
        </div>
        <div class="info" v-html="contentData.articleContent"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getColumnContent, getdown, getEntityById } from "../api/index.js";
  import store from "@/store";
  export default {
    data() {
      return {
        baseIP: process.env.VUE_APP_BASE_URL,
        imageShowAPI: "/yuan/files/getFileStream/",
        virtualDataMuseum: [], // 博物馆数据
        virtualDataCity: [], // 博物馆数据
        contentData: {},
        signColor: 0,
        idlogo: "",
        defaultImage: require('../assets/imgs/enterpriseDefault.jpg'),
      };
    },
    created() {
      if (this.$route.query.id) {
        getEntityById({ id: this.$route.query.id }).then((res) => {
          console.log(res, 'res')
          this.contentData = res.data;
          this.idlogo = res.data.id;
        });
      }
      this.init()
    },
    methods: {
      init() {
        // 获取智慧博物馆的数据
        getColumnContent({ levelType: "33" }).then((res) => {
          if (res.code === 0) {
            this.virtualDataMuseum = res.data.content;
            if (!this.$route.query.id) {
              this.contentData = res.data.content[0];
              this.idlogo = res.data.content[0].id;
            }
          }
        });
        // 获取智慧城市的数据
        getColumnContent({ levelType: "34" }).then((res) => {
          if (res.code === 0) {
            this.virtualDataCity = res.data.content;
          }
        });
      },
      getContent(ids) {
        this.idlogo = ids;
        getdown({ id: ids }).then((res) => {
          if (res.code === 0) {
            this.contentData = res.data;
          }
        });
      },
    },
    // mounted () {
    //   if(this.$route.query.id){
    //     getEntityById({id:this.$route.query.id}).then((res)=>{
    //       console.log(res,'res')
    //     })
    //   }
    // }
  };
</script>

<style lang="scss" scoped>
  .software-list {
    margin: 94px 0 100px 0;
    display: flex;
    justify-content: left;
    .left-option {
      min-width: 265px;
      height: 100%;
      background-color: #ffffff;
      box-shadow: 0px 3px 3px 0px rgba(4, 0, 0, 0.05);
      border-radius: 4px;
      border: solid 1px #e9e9e9;
      .museum {
        width: 100%;
        height: 41px;
        line-height: 41px;
        color: #fefefe;
        box-sizing: border-box;
        padding: 0 10px;
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #0f6ebe;
      }
      .museum-list {
        width: 100%;
        max-height: 125px;
        line-height: 115px;
        box-sizing: border-box;
        overflow: auto;
        ul {
          line-height: 115px;
          li {
            height: 40px;
            line-height: 40px;
            text-align: left;
            font-size: 18px;
            box-sizing: border-box;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;
            border-bottom: 1px solid #e9e9e9;
            span {
              margin: 0 10px;
            }
          }
          li:hover {
            color: #0f6ebe;
          }
        }
      }
      /*滚动条样式*/
      .museum-list::-webkit-scrollbar {
        width: 2px;
        /*height: 4px;*/
      }
      .museum-list::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px #ccc;
        background: transparent;
      }
    }
    .right-list-box {
      // width: 100%;
      // height: 100%;
      margin-left: 95px;
      .right-list {
        display: flex;
        justify-content: left;
        align-items: top;
        border-bottom: 1px solid #dfdfdf;
        padding: 0 0 50px 0;
        .imgs {
          width: 420px;
          height: 280px;
          margin-right: 40px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            cursor: pointer;
            transition: all 0.9s;
          }
          img:hover {
            transform: scale(1.3);
          }
        }
        .text {
          width: 560px;
          // height: 300px;
          span {
            display: block;
            font-family: MicrosoftYaHei;
            height: 30px;
            line-height: 30px;
            font-size: 20px;
            text-align: left;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-weight: bold;
            margin-bottom: 20px;
          }
          p {
            line-height: 28px;
            font-size: 16px;
            letter-spacing: 0px;
            color: #333333;
            text-align: left;
            // text-overflow: -o-ellipsis-lastline;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-line-clamp: 10;
            // -webkit-box-orient: vertical;
          }
        }
      }
      .info {
        margin-top: 50px;
        text-align: left;
        font-size: 22px;
        /deep/ p {
          display: flex;
          justify-content: left;
          flex-flow: wrap;
          img {
            margin-right: 20px;
            margin-top: 20px;
          }
        }
      }
    }
    .textColor {
      color: #0f6ebe;
    }
  }
</style>