<template>
  <div class="manage">
    <!-- 顶部图片 -->
    <div class="imgs">
      <img src="../../assets/imgs/banner2.jpg" alt="">
    </div>
    <div class="nav">
      <ul class="w">
        <li @click="addNum(0, 'exhibition')" :class="{ active: num === 'exhibition' }">展览展示</li>
        <li @click="addNum(1, 'videoProduction')" :class="{ active: num === 'videoProduction' }">视频制作</li>
        <li @click="addNum(2, 'virtual')" :class="{ active: num === 'virtual' }">虚拟展示</li>
        <li @click="addNum(3, 'desktop')" :class="{ active: num === 'desktop' }">桌面应用</li>
      </ul>
    </div>
    <div class="w content">
      <div class="protect">
        <Virtual></Virtual>
      </div>
    </div>
  </div>
</template>

<script>
import Virtual from "@/components/virtual";

export default {
  data() {
    return {
      num: ''
    };
  },
  mounted() {
    this.$store.commit('ip/SET_routefullPath', this.$route.fullPath)
    this.num = this.$route.query.num
  },
  methods: {
    addNum(i, val) {
      this.num = val
      this.$router.push({ name: val, query: { num: val } })
    }
  },
  components: {
    Virtual
  },
};
</script>

<style lang="scss" scoped>
.imgs {
  width: 100%;

  img {
    width: 100%;
    height: 100%;
    vertical-align: bottom;
  }
}

.nav {
  height: 63px;
  line-height: 60px;
  background-color: #fbfbfb;
  border: solid 1px #ededed;
  margin-bottom: 10px;

  ul {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    li {
      min-width: 100px;
      font-size: 20px;
      color: #666666;
      box-sizing: border-box;
      border-bottom: 3px solid #fff;
      cursor: pointer;
    }

    li:nth-child(2) {
      margin: 0 50px;
    }

    li:nth-child(3) {
      margin: 0 50px 0 0;
    }

    .active {
      border-bottom-color: #e43838;
      color: #333333;
    }
  }
}

.content {
  .protect {
    margin-bottom: 10px;
  }

  .service {
    margin-bottom: 10px;
  }

  .manage {
    margin-bottom: 10px;
  }
}
</style>