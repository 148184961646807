<template>
  <div class="exhibit">
    <div class="exhibit-img">
      <span v-for="item of virtualStat" :key="item.id">
        <div class="imgs">
          <!-- <img :src="baseIP + imageShowAPI + item.imageUri" alt="" /> -->
          <!-- <img :src="item.contentImageId && baseIP + imageShowAPI + item.contentImage.waterMarkUri" alt="" /> -->

          <img v-if="item.contentImageId &&item.contentImage" :src="item.contentImageId && baseIP + imageShowAPI + item.contentImage.waterMarkUri" alt="" />
          <img v-else :src="defaultImage" alt="" />
        </div>
        <h4>{{ item.title }}</h4>
      </span>
    </div>
    <div class="btn">
      <span @click="moreData"><em class="el-icon-arrow-down"></em> 查看更多</span>
    </div>
  </div>
</template>

<script>
  import { getColumnContent } from "../api/index.js";
  import store from "@/store";
  export default {
    data() {
      return {
        baseIP: process.env.VUE_APP_BASE_URL,
        imageShowAPI: "/yuan/files/getFileStream/",
        defaultImage: require('../assets/imgs/enterpriseDefault.jpg'),
        virtualData: [],
        virtualStat: []
      };
    },
    created() {
      // 视频制作数据
      getColumnContent({ optionType: "25" }).then((res) => {
        if (res.code === 0) {
          this.virtualData = res.data.content;
          this.virtualStat = this.virtualData.slice(0, 6)
        }
      });
    },
    methods: {
      moreData() {
        const length = this.virtualData.length;
        if (length > this.virtualStat.length) {
          this.virtualStat = this.virtualData.slice(
            0,
            this.virtualStat.length + 2
          );
        } else {
          this.$message({
            type: "warning",
            message: "没有更多数据了",
          });
        }
      },
    }
  };
</script>

<style lang="scss" scoped>
  .exhibit-img {
    margin-top: 60px;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;

    span {
      display: inline-block;
      width: 677px;
      height: 416px;
      cursor: pointer;
      margin-top: 20px;

      .imgs {
        width: 677px;
        height: 361px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          transition: all 0.9s;
        }

        img:hover {
          transform: scale(1.2);
        }
      }

      h4 {
        font-weight: normal;
        height: 48px;
        line-height: 48px;
        font-size: 24px;
        color: #333333;
        overflow: hidden;
        margin-top: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    span:nth-child(odd) {
      margin-right: 23px;
    }
  }

  .btn {
    margin: 50px 0;

    span {
      display: inline-block;
      width: 150px;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
      font-size: 20px;
      color: #999999;

      em {
        margin-right: 10px;
      }
    }
  }
</style>