<template>
  <div class="software">
    <div class="software-list">
      <div class="left-option">
        <div class="museum">
          <span>智慧博物馆传感器</span>
          <em class="el-icon-arrow-right"></em>
        </div>
        <div class="museum-list">
          <ul>
            <li v-for="(item) of virtualDataMuseum" :key="item.id" @click="getContent(item.id)" :class="{textColor:idlogo===item.id}"><span>{{item.title}}</span></li>
          </ul>
        </div>
        <div class="museum">
          <span>智慧城市传感器</span>
          <em class="el-icon-arrow-right"></em>
        </div>
        <div class="museum-list">
          <ul>
            <li v-for="item of virtualDataCity" :key="item.id" @click="getContent(item.id)" :class="{textColor:idlogo===item.id}"><span>{{item.title}}</span></li>
          </ul>
        </div>
      </div>
      <div class="right-list-box">
        <div class="right-list" v-if="contentData">
          <div class="text">
            <span>介绍</span>
            <p v-html="contentData.columnsContent"></p>
          </div>
          <div class="imgs">
            <!-- <img :src="baseIP + imageShowAPI + contentData.imageUri" alt=""> -->
            <!-- <img :src="contentData && contentData.contentImageId && baseIP + imageShowAPI + contentData.contentImage.waterMarkUri" alt="" /> -->
            <img v-if="contentData.contentImageId &&contentData.contentImage" :src="contentData.contentImageId && baseIP + imageShowAPI + contentData.contentImage.waterMarkUri" alt="" />
            <img v-else :src="defaultImage" alt="" />
          </div>
        </div>
        <div class="application">
          <span>行业应用</span>
          <div class="img-application" v-if="industry" v-html="industry[0]"></div>
          <div class="noData" v-else>暂无数据</div>
        </div>
        <div class="application2">
          <span>产品参数</span>
          <div class="img-application2" v-if="industry2" v-html="industry2[0]"></div>
          <div class="noData2" v-else>暂无数据</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getColumnContent, getdown, getEntityById } from "../api/index.js";
  import store from "@/store";
  export default {
    data() {
      return {
        defaultImage: require('../assets/imgs/enterpriseDefault.jpg'),
        baseIP: process.env.VUE_APP_BASE_URL,
        imageShowAPI: "/yuan/files/getFileStream/",
        virtualDataMuseum: [], // 博物馆数据
        virtualDataCity: [], // 博物馆数据
        contentData: {},
        signColor: 0,
        idlogo: '',
        industry: '', // img标签内容
        industry2: '' // 表格内容
      };
    },
    created() {
      console.log(this.$route, 'this.$route')
      // if(this.$route.query.id){
      //   this.getContent(this.$route.query.id)
      // }
      if (this.$route.query.id) {
        getEntityById({ id: this.$route.query.id }).then((res) => {
          console.log(res, 'res')
          this.contentData = res.data;
          this.idlogo = res.data.id;
        });
      }
      // 获取智慧博物馆传感器的数据
      getColumnContent({ levelType: "35" }).then((res) => {
        console.log(res, '智慧博物馆传感器')
        if (res.code === 0) {
          this.virtualDataMuseum = res.data.content;
          this.contentData = res.data.content[0]

          // 匹配img标签
          let imgReg = /<img.*?(?:>|\/>)/gi //匹配图片中的img标签
          if (this.contentData.articleContent !== null) {
            this.industry = this.contentData.articleContent.match(imgReg)  //筛选出所有的img
          }

          // 匹配table标签
          var tabReg = /<table[^>]*>\s*(<tbody[^>]*>)?(\s*<tr[^>]*>[\s\S]*?<\/tr>\s*)*(<\/tbody>)?\s*<\/table>/gi;
          if (this.contentData.articleContent !== null) {
            this.industry2 = this.contentData.articleContent.match(tabReg)
          }

          if (res.data.content.length > 0) {
            this.idlogo = res.data.content[0].id
          }
        }
      });
      // 获取智慧城市传感器的数据
      getColumnContent({ levelType: "36" }).then((res) => {
        console.log(res, '智慧城市传感器')
        if (res.code === 0) {
          this.virtualDataCity = res.data.content;
        }
      });
    },
    methods: {
      getContent(ids) {
        this.idlogo = ids
        console.log(ids, 'ids')
        getdown({ id: ids }).then((res) => {
          console.log(res, 'ids内容')
          if (res.code === 0) {
            this.contentData = res.data

            // 匹配img标签
            let imgReg = /<img.*?(?:>|\/>)/gi //匹配图片中的img标签
            if (this.contentData.articleContent !== null) {
              this.industry = this.contentData.articleContent.match(imgReg)  //筛选出所有的img
            } else {
              this.industry = ''
            }

            // 匹配table标签
            var tabReg = /<table[^>]*>\s*(<tbody[^>]*>)?(\s*<tr[^>]*>[\s\S]*?<\/tr>\s*)*(<\/tbody>)?\s*<\/table>/gi;
            if (this.contentData.articleContent !== null) {
              this.industry2 = this.contentData.articleContent.match(tabReg)
            } else {
              this.industry2 = ''
            }
          }
        })
      }
    },
  };
</script>

<style lang="scss" scoped>
  .software-list {
    margin: 94px 0 100px 0;
    display: flex;
    justify-content: left;
    .left-option {
      min-width: 265px;
      height: 100%;
      background-color: #ffffff;
      box-shadow: 0px 3px 3px 0px rgba(4, 0, 0, 0.05);
      border-radius: 4px;
      border: solid 1px #e9e9e9;
      .museum {
        width: 100%;
        height: 41px;
        line-height: 41px;
        color: #fefefe;
        box-sizing: border-box;
        padding: 0 10px;
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #0f6ebe;
      }
      .museum-list {
        width: 100%;
        // height: 125px;
        max-height: 125px;
        line-height: 115px;
        box-sizing: border-box;
        overflow: auto;
        ul {
          line-height: 115px;
          li {
            height: 40px;
            line-height: 40px;
            text-align: left;
            font-size: 18px;
            box-sizing: border-box;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;
            border-bottom: 1px solid #e9e9e9;
            span {
              margin: 0 10px;
            }
          }
          // li:last-child {
          //   border-bottom: none;
          // }
          li:hover {
            color: #0f6ebe;
          }
        }
      }
      /*滚动条样式*/
      .museum-list::-webkit-scrollbar {
        width: 2px;
        /*height: 4px;*/
      }
      .museum-list::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px #ccc;
        background: transparent;
      }
    }
    .right-list-box {
      // width: 100%;
      height: 100%;
      margin-left: 95px;
      .right-list {
        display: flex;
        justify-content: left;
        // align-items: center;
        border-bottom: 1px solid #eee;
        .imgs {
          width: 345px;
          // height: 300px;
          height: 100%;
          margin-right: 40px;
          overflow: hidden;
          margin-bottom: 50px;
          img {
            width: 100%;
            height: 100%;
            cursor: pointer;
            transition: all 0.9s;
          }
          img:hover {
            transform: scale(1.3);
          }
        }
        .text {
          width: 500px;
          min-height: 300px;
          margin-right: 20px;
          margin-bottom: 50px;
          span {
            display: block;
            font-family: MicrosoftYaHei;
            height: 30px;
            line-height: 30px;
            font-size: 26px;
            text-align: left;
            margin-bottom: 20px;
          }
          p {
            line-height: 28px;
            font-size: 18px;
            letter-spacing: 0px;
            color: #333333;
            text-align: left;
            text-indent: 2em;
            // text-overflow: -o-ellipsis-lastline;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-line-clamp: 10;
            // -webkit-box-orient: vertical;
          }
        }
      }
      .application {
        margin-top: 75px;
        display: flex;
        justify-content: left;
        span {
          display: inline-block;
          width: 150px;
          height: 50px;
          line-height: 50px;
          font-size: 26px;
          text-align: left;
          // margin-right: 20px;
        }
        .img-application {
          // width: 750px;
          margin-top: 10px;
          /deep/ img {
            width: 100% !important;
          }
        }
        .noData {
          font-size: 26px;
          margin-top: 5px;
        }
      }
      .application2 {
        margin-top: 75px;
        display: flex;
        justify-content: left;
        span {
          display: inline-block;
          width: 150px;
          height: 50px;
          line-height: 50px;
          font-size: 26px;
          text-align: left;
          // margin-right: 20px;
        }
        .img-application2 {
          width: 100%;
          margin-top: 10px;
          /deep/ img {
            width: 100% !important;
          }
        }
        .noData2 {
          font-size: 26px;
          margin-top: 5px;
        }
      }
      .industry-parameters {
        margin-top: 50px;
        text-align: left;
      }
    }
    .textColor {
      color: #0f6ebe;
    }
  }
</style>